<template>
  <div class="warp">
    <!-- 头部 -->
    <div class="warpTop">
      <div class="warpTops">
        <!-- 中间内容 -->
        <div class="warpTopsCent">
          <!-- 左侧视频 -->
          <div class="warpTopsCentL">
            <img :src="detail.img" />
          </div>
          <!-- 右侧详情 -->
          <div class="warpTopsCentR">
            <!-- 标题 -->
            <div class="divp">{{ detail.title }}</div>
            <div class="tip">
              <span class="text-FF684B"
                >{{ detail.clicks ? detail.clicks : 0 }}同学预约
              </span>
              <i class="iconfont icon-dian"></i>
              来自公共课
            </div>
            <!-- 价格 -->
            <div class="picr">
              <p>
                课程安排<span class="spana">{{
                  detail.startEnd ? detail.startEnd.slice(0, 19) : ""
                }}</span>
              </p>
              <p>课程类型<span class="spanb">公开课</span></p>
            </div>
            <div class="iflive">{{ IsLive[detail.isLive] }}</div>
            <!-- 报名资讯按钮 -->
            <div class="btn">
              <div
                class="btnL"
                v-if="
                  detail.jgPrice && detail.jgPrice > 0 && !detail.IsPurchase
                "
                @click="goOrder(4, detail)"
              >
                立即购买
              </div>

              <div v-else>
                <div
                  class="btnL"
                  v-if="detail.isLive == 0"
                  @click.stop="appointment(detail)"
                >
                  {{ detail.isPublic ? "立即预约" : "预约成功" }}
                </div>
                <div
                  class="btnL"
                  v-else-if="detail.isLive == 1"
                  @click="guankan"
                >
                  进入直播
                </div>
                <div class="btnL" v-else-if="detail.isLive == 2">
                  回放生成中
                </div>
                <div
                  class="btnL"
                  v-else-if="detail.isLive == 3"
                  @click="guankan"
                >
                  观看课程
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部内容 -->
        <div class="warpTopsFot">
          <el-popover placement="bottom" width="340" trigger="click">
            <div class="fxPoins">
              <p>登录分享有礼! 呼唤你的小伙伴来围观吧！</p>
              <div class="fxPoinsTop">
                <img
                  style="cursor: pointer"
                  src="@/assets/img/kecheng/qq.png"
                />
                <img
                  style="cursor: pointer"
                  src="@/assets/img/kecheng/wx.png"
                />
                <img
                  style="cursor: pointer"
                  src="@/assets/img/kecheng/wb.png"
                />
                <img
                  style="cursor: pointer"
                  src="@/assets/img/kecheng/kj.png"
                />
              </div>
              <div
                style="
                  width: auto;
                  height: 1px;
                  margin: 0px 23px;
                  margin-top: 27px;
                  background: #eeeeee;
                "
              ></div>
              <div class="fxPoinsBtm">
                <span>分享链接</span><input :value="href" type="text" />
                <div @click="clickCopy(href)">复制</div>
              </div>
            </div>
            <div class="warpTopsFotL" slot="reference">
              <img src="@/assets/img/kecheng/classIcon14.png" />
              <span>分享</span>
            </div>
          </el-popover>
          <div class="warpTopsFotR">温馨提示：本课程可在手机端观看</div>
        </div>
      </div>
    </div>
    <!-- 选项栏 -->
    <div class="warpnav">
      <div class="warpnavs">
        <!-- 课程详情 选中 -->
        <div class="warpnavsCon">
          <span>课程详情</span>
          <div></div>
        </div>
      </div>
    </div>
    <!-- 课程详情内容 -->
    <div class="warpCon">
      <div class="apple">
        <div class="appleLeft">
          <div class="appleLefts" v-html="detail.content"></div>
        </div>
        <!-- 右侧推荐 -->
        <you-love :id="id" :type="4">相关课程</you-love>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getDetail, getClassRelevant } from "@/api/home";
import { IsLive } from "@/api/emun";
import youLove from "@/components/Know/youLove.vue";
import { getToken, getInfo } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
let orderClient = new OrderClient();
import { Know } from "@/api/know";
let know = new Know();
export default {
  components: { youLove },
  data() {
    return {
      userInfo: null,
      id: null,
      IsLive: IsLive,
      detail: {},
      shser: "",
      openList: [],
      href: "",
      inviterId: null, //邀请人
    };
  },
  created() {
    this.inviterId = this.$route.query.inviterId;
    this.userInfo = getInfo();
    this.href =
      window.location.href +
      `&inviterId=${this.userInfo ? this.userInfo.id : ""}`;
    this.id = this.$route.query.id;
    this.getDetail();
    if (this.inviterId) {
      this.addPublicKe();
    }
  },
  methods: {
    // 公开课详情
    getDetail() {
      getDetail(this.id).then((res) => {
        this.detail = res.data.GKK;
        this.detail.jgPrice = res.data.jgPrice;
        this.shser = res.data.IsPurchase;
      });
    },
    // 观看课程
    guankan() {
      let token = getToken();
      if (token) {
        this.$router.push(`/openclassbofang?id=${this.detail.id}`);
        // if(this.shser == true){
        //   this.$router.push(`/openclassbofang?id=${this.detail.id}`);
        // } else {
        //   alert("请先购买课程")
        // }
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    // 立即购买
    goOrder(type, item) {
      let token = getToken();
      if (token) {
        orderClient
          .addOrders(item.id, localStorage.getItem("userId"), item.title, type,item.title,item.kind)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    /* 预约 */
    appointment(item) {
      if (!this.detail.isPublic) {
        return;
      }
      if (!this.userInfo) {
        Vue.prototype.goLoginView
        return;
      }
      know
        .addUserPublicKe(
          item.id,
          this.userInfo.nickName,
          this.userInfo.mobile,
          this.userInfo.id
        )
        .then((res) => {
          if (res.code == 0) {
            this.getDetail();
          }
        });
    },
    //分享
    /* 添加邀请记录 */
    addPublicKe() {
      if (!this.userInfo) {
        Vue.prototype.goLoginView

        return;
      }
      if (this.inviterId == this.userInfo.id) {
        return;
      }
      know.addUserPublicKeInvite(
        this.id,
        this.inviterId,
        this.userInfo.id,
        this.userInfo.account
      );
    },
  },
};
</script>
<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
  overflow: hidden;
  // 头部
  .warpTop {
    width: 100%;
    height: 570px;
    background: linear-gradient(30deg, #fff3e8 0%, #ffe7dd 74%, #ffefe8 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    .warpTops {
      width: 1200px;
      height: 540px;
      background: #ffffff;
      padding: 76px 0px 0px 34px;
      // 标题
      .warpTopsName {
        width: 100%;
        height: 76px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
        }
        img {
          width: 12px;
          height: 12px;
          margin: 0px 10px;
          margin-top: 3px;
        }
        .spanb {
          color: #333333;
          cursor: pointer;
        }
      }
      // 中间内容
      .warpTopsCent {
        width: 100%;
        height: 366px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // 左侧视频
        .warpTopsCentL {
          width: 651px;
          height: 100%;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        // 右侧详情
        .warpTopsCentR {
          width: auto;
          height: 100%;
          margin-left: 30px;
          position: relative;
          // 标题
          .divp {
            width: 460px;
            height: auto;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          .tip {
            margin-top: 12px;
            font-size: 14px;
            height: 19px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #747c80;
          }
          // 课程安排
          .picr {
            width: 460px;
            height: 80px;
            margin-top: 24px;
            background: #f4f4f4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            p {
              width: auto;
              height: auto;
              margin-top: 5px;
              margin-bottom: 5px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              margin-left: 10px;
              .spana {
                color: #000000;
                margin-left: 20px;
              }
              .spanb {
                color: #ff6029;
                margin-left: 20px;
              }
            }
          }
          // 直播状态
          .iflive {
            margin-top: 73px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #999999;
          }
          // 报名资讯按钮
          .btn {
            width: auto;
            height: 46px;
            margin-top: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            bottom: 0px;
            // 立即报名
            .btnL {
              width: 140px;
              height: 46px;
              margin-right: 20px;
              background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
              cursor: pointer;
            }
            // 立即咨询
            .btnR {
              width: 140px;
              height: 46px;
              background: #ffeee7;
              border: 1px solid #ff7a3e;
              border-radius: 2px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ff7a3e;
              cursor: pointer;
            }
          }
        }
      }
      // 底部内容
      .warpTopsFot {
        width: 651px;
        height: 19px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .warpTopsFotL {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          img {
            width: 20px;
            height: 19px;
            margin-right: 8px;
          }
          span {
            width: auto;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            margin-right: 30px;
          }
        }
        .warpTopsFotR {
          width: auto;
          height: 100%;
          width: 211px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 12px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
        }
      }
    }
  }
  // 选项栏
  .warpnav {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border: 1px solid #dddddd;
    // 缩宽
    .warpnavs {
      width: 1200px;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // 知识套餐概述
      span {
        width: auto;
        height: auto;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      // 竖线
      .line {
        width: 1px;
        height: 14px;
        background: #cccccc;
        margin: 0px 40px;
        margin-top: 5px;
      }
      // 转换内容 选中
      .warpnavsCon {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ff4027;
          margin-top: 20px;
        }
        div {
          width: 30px;
          height: 3px;
          margin-top: 14px;
          background: linear-gradient(90deg, #ff4027 0%, #ff5400 100%);
        }
      }
      // 转换内容 未选中
      .warpnavsCons {
        width: 70px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        span {
          width: auto;
          height: auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-top: 20px;
        }
      }
    }
  }
  // 直播课内容
  .warpCon {
    width: 100%;
    // height: 920px;
    height: auto;
    margin-bottom: 30px;
    background: #f5f6fa;
    overflow: hidden;
    // 缩宽
    .warpCons {
      width: 1200px;
      height: 100%;
      margin: auto;
      overflow: hidden;
      margin-bottom: 50px;
      .warpConss {
        width: 100%;
        height: auto;
        // 单个内容
        .warpConske {
          width: 100%;
          height: 76px;
          margin-top: 30px;
          background: #ffffff;
          border: 1px solid #ffc3bb;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // 左侧标题
          .warpConskeL {
            width: auto;
            height: 19px;
            margin-left: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              width: 21px;
              height: 19px;
              margin-right: 16px;
              cursor: pointer;
            }
            p {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #27323f;
              cursor: pointer;
              span {
                width: auto;
                height: auto;
                margin-left: 20px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7b8196;
              }
            }
          }
          // 右侧展开/收起
          .warpConskeR {
            width: auto;
            height: 15px;
            margin-right: 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            img {
              width: 9px;
              height: 7px;
              margin-left: 7px;
            }
            span {
              width: auto;
              height: auto;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7b8196;
            }
          }
        }
        // 单个内容展开项盒子
        .warpConskexq {
          width: 100%;
          height: auto;
          background: #ffffff;
          padding: 20px;
          // 某个展开项
          p {
            width: auto;
            height: 13px;
            font-size: 15px;
            margin: 20px 0px 30px 10px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #27323f;
          }
          .warpConskexqs {
            width: 1175px;
            height: 50px;
            margin: 10px auto;
            background: #f7f7f7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // 左侧
            .warpConskexqsL {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 4px;
                height: 50px;
                background: #dddddd;
                margin-right: 27px;
              }
              span {
                width: auto;
                height: auto;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                cursor: pointer;
              }
            }
            // 右侧
            .warpConskexqsR {
              width: 320px;
              height: 32px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              div {
                width: 90px;
                height: 32px;
                margin-right: 10px;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    .apple {
      width: 1200px;
      height: 100%;
      margin: 30px auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .appleLeft {
        width: 750px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: #ffffff;
        .appleLefts {
          width: auto;
          height: auto;
          padding: 24px 25px;
          /deep/ img {
            width: 100% !important;
          }
        }
      }
      .appleRight {
        width: 430px;
        height: 1292px;
        background: #ffffff;
        .appleRightTop {
          width: auto;
          height: 20px;
          margin: 16px 22px;
          border-left: 6px solid #3855f9;
          padding: 0px 12px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e7e7e;
        }
        img {
          width: 376px;
          height: 209px;
          margin-top: 25px;
          margin-left: 27px;
          background: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}
.el-popover {
  overflow: hidden;
  padding: 0px;
}
</style>
